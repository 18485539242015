<template>
  <div style="margin-top: 2rem">
    <div class="table-container">
      <div class="add-container">
        <el-input
          placeholder="Type to search"
          v-model="searchKey"
          style="width: 30%"></el-input>
        <el-button type="primary" @click="dialogVisible = true"
          >Add Payment Gateway</el-button
        >
      </div>

      <el-table :data="chartAccounts" height="50vh" v-loading="tableLoading">
        <el-table-column label="Account ID" prop="account_id"></el-table-column>
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column label="Debit Account">
          <template slot-scope="scope">
            <p style="margin: 0 auto">
              {{ scope.row.debit_account_id?.name || "not set" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Charge Account">
          <template slot-scope="scope">
            <p style="margin: 0 auto">
              {{ scope.row.charge_account_id?.name || "not set" }}
            </p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Charge" prop="charge"></el-table-column> -->
        <el-table-column label="Status">
          <template slot-scope="scope">
            <p
              v-if="scope.row.status == 0"
              style="color: #ee6463; margin: 0 auto">
              Inactive
            </p>
            <p
              v-if="scope.row.status == 1"
              style="color: #56db71; margin: 0 auto">
              Active
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Action" prop="">
          <template slot-scope="scope">
            <div>
              <button
                title="Update Chart Account"
                class="edit-button"
                @click="openEditDialog({ row: scope.row })">
                <editIcon class="is-editing"></editIcon>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="col-md-12" style="text-align: center; margin-top: 1em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="modal-container">
      <el-dialog title="Add Account" :visible.sync="dialogVisible" width="30%">
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          @submit.prevent.native="checkEnter"
          label-position="left">
          <el-form-item label="Name" prop="name">
            <el-input
              placeholder="Account ID"
              v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item
            label="Debit Account"
            style="display: flex; flex-direction: column">
            <el-select
              v-model="formData.debit_account_id"
              filterable
              style="width: 100%">
              <el-option
                v-for="(option, index) in selectListNotExpense"
                :key="index"
                :value="option.name"
                :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Charge Account"
            style="display: flex; flex-direction: column">
            <el-select
              v-model="formData.charge_account_id"
              filterable
              style="width: 100%">
              <el-option
                v-for="(option, index) in selectListExpense"
                :key="index"
                :value="option.name"
                :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="Charge Amount" prop="charge">
                        <el-input placeholder="Account ID" v-model="formData.charge"></el-input>
                    </el-form-item> -->
          <el-form-item
            label="Status"
            prop="status"
            style="display: flex; flex-direction: column">
            <el-radio-group v-model="formData.status">
              <el-radio label="1" :value="1">Enable</el-radio>
              <el-radio label="0" :value="0">Disable</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-button
            type="primary"
            style="width: 100%; margin: 0 auto"
            @click="checkEnter"
            >Submit</el-button
          >
        </el-form>
      </el-dialog>
      <!-- Confirmation -->
      <el-dialog title="Confirm" :visible.sync="confirmModal">
        <div class="heading">
          <p>Are you sure you want to add this account?</p>
        </div>
        <div class="preview">
          <p style="font-size: 11px">
            Account Name: &nbsp;
            <strong style="font-size: 14px">{{ formData.name }}</strong>
          </p>
          <p style="font-size: 11px">
            Debit Account: &nbsp;
            <strong style="font-size: 14px">{{
              formData.debit_account_id
            }}</strong>
          </p>
          <p style="font-size: 11px">
            Charge Account: &nbsp;
            <strong style="font-size: 14px">{{
              formData.charge_account_id
            }}</strong>
          </p>
          <p style="font-size: 11px">
            Charge: &nbsp;
            <strong style="font-size: 14px">{{ formData.charge }}</strong>
          </p>
          <p style="font-size: 11px">
            Status: &nbsp;
            <strong style="font-size: 14px">{{ formData.status }}</strong>
          </p>
        </div>
        <div class="submit-container">
          <el-button type="primary" @click="confirmModal = false"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="submitNewData"
            :loading="buttonLoading"
            >Confirm</el-button
          >
        </div>
      </el-dialog>
      <!-- Edit -->
      <el-dialog
        title="Edit Account"
        :visible.sync="editDialogVisible"
        width="30%"
        v-if="editDialogVisible">
        <el-form :model="editFormData" ref="EditForm" label-position="left">
          <el-form-item label="Name" prop="name">
            <el-input
              placeholder="Account ID"
              v-model="editFormData.name"></el-input>
          </el-form-item>
          <el-form-item
            label="Debit Account"
            style="display: flex; flex-direction: column">
            <el-select
              v-model="editFormData.debit_account_id"
              filterable
              style="width: 100%">
              <el-option
                v-for="(option, index) in selectListNotExpense"
                :key="index"
                :value="option.name"
                :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Charge Account"
            style="display: flex; flex-direction: column">
            <el-select
              v-model="editFormData.charge_account_id"
              filterable
              style="width: 100%">
              <el-option
                v-for="(option, index) in selectListExpense"
                :key="index"
                :value="option.name"
                :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Status"
            prop="status"
            style="display: flex; flex-direction: column">
            <el-radio-group v-model="editFormData.status">
              <el-radio :label="1" :value="1">Enable</el-radio>
              <el-radio :label="0" :value="0">Disable</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-button
            type="primary"
            :loading="buttonLoading"
            style="width: 100%; margin: 0 auto"
            @click="updateDetails"
            >Submit</el-button
          >
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import editIcon from "./editIcon.vue";
  export default {
    components: {
      editIcon,
    },
    data() {
      return {
        dialogVisible: false,
        confirmModal: false,
        editDialogVisible: false,
        page: 1,
        pageSize: 20,
        total: 0,
        buttonLoading: false,
        tableLoading: false,
        searchKey: "",
        selectList: [],
        formData: {
          name: "",
          debit_account_id: "",
          charge_account_id: "",
          charge: "",
          status: "",
        },
        editFormData: {
          name: "",
          debit_account_id: "",
          charge_account_id: "",
          charge: "",
          status: "",
        },
        rules: {
          name: [
            {
              required: true,
              message: "Account name is required.",
              trigger: "change",
            },
          ],
          debitAccount: [
            {
              required: true,
              message: "Debit account is required",
              trigger: "blur",
            },
          ],
          chargeAccount: [
            {
              required: true,
              message: "Charge account is required",
              trigger: "blur",
            },
          ],
          status: [
            {
              required: true,
              message: "Status is required",
              trigger: "change",
            },
          ],
          charge: [
            {
              required: true,
              message: "Charge value is required",
              trigger: "change",
            },
          ],
        },
      };
    },
    mounted() {
      this.tableLoading = true;
      store
        .dispatch("admin/reporting/getPaymentAggregates")
        .then((data) => {
          this.tableLoading = false;
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            message: err.message,
          });
        });
      store
        .dispatch("admin/reporting/getChartAccounts")
        .then((data) => {
          this.selectList = data;
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            message: err.message,
          });
        });
    },
    methods: {
      checkEnter(e) {
        e.preventDefault();
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.confirmModal = true;
          } else {
            return false;
          }
        });
      },
      submitNewData() {
        this.buttonLoading = true;
        store
          .dispatch("admin/reporting/addPaymentAggregates", this.formData)
          .then((data) => {
            this.buttonLoading = false;
            this.confirmModal = false;
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "Gateway added successfully!",
            });
            this.$refs.ruleForm.resetFields();
            this.refetchData();
          })
          .catch((err) => {
            this.buttonLoading = false;
            this.$notify.error({
              title: "Error",
              message: err.message,
            });
          });
      },
      handleCurrentChange(val) {
        this.page = val;
      },
      refetchData() {
        this.tableLoading = true;
        store.dispatch("admin/reporting/getPaymentAggregates").then((data) => {
          this.tableLoading = false;
        });
      },
      openEditDialog(row) {
        this.editFormData = {
          name: row.row.account_id,
          debit_account_id: row.row.debit_account_id?.name,
          charge_account_id: row.row.charge_account_id?.name,
          status: row.row.status,
          charge: row.row.charge,
        };
        this.editDialogVisible = true;
      },
      updateDetails(e) {
        e.preventDefault();
        // validate form
        if (
          this.editFormData.name == "" ||
          this.editFormData.description == "" ||
          this.editFormData.currency == "" ||
          this.editFormData.charge == "" ||
          this.editFormData.status == ""
        ) {
          this.$message({
            type: "error",
            message: "Please fill all fields!",
          });
          return;
        } else {
          this.buttonLoading = true;
          const payload = {
            name: this.editFormData.name,
            debit_account_id: this.editFormData.debit_account_id,
            charge_account_id: this.editFormData.charge_account_id,
            status: this.editFormData.status,
            charge: this.editFormData.charge,
          };
          store
            .dispatch("admin/reporting/updatePaymentAggregates", payload)
            .then((data) => {
              this.buttonLoading = false;
              this.editDialogVisible = false;
              this.$message({
                type: "success",
                message: "Account updated successfully!",
              });
              this.refetchData();
            })
            .catch((err) => {
              this.buttonLoading = false;
              this.editDialogVisible = false;
              this.$message({
                type: "error",
                message: "Error updating account!",
              });
            });
        }
      },
    },
    computed: {
      chartAccounts() {
        return store.getters["admin/reporting/paymentAggregates"].filter(
          (item) => {
            return (
              item.debit_account_id?.name
                .toLowerCase()
                .includes(this.searchKey.toLowerCase()) ||
              item.charge_account_id?.name
                .toLowerCase()
                .includes(this.searchKey.toLowerCase()) ||
              item.charge
                .toLowerCase()
                .includes(this.searchKey.toLowerCase()) ||
              item?.name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
              item.account_id
                .toLowerCase()
                .includes(this.searchKey.toLowerCase())
            );
          }
        );
      },
      selectListNotExpense() {
        return this.selectList.filter((item) => {
          return item.account_type.includes("Expense") == false;
        });
      },
      selectListExpense() {
        return this.selectList.filter((item) => {
          return item.account_type.includes("Expense");
        });
      },
    },
  };
</script>
<style scoped>
  .add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  .edit-button {
    background-color: transparent;
    border: none;
  }
</style>
